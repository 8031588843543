<template>
	<div class="half_layer_wrap">
		<div class="bg-dark" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; opacity: 0.5; background-color: black"></div>
		<div class="half_layer_wrap_inner" style="border-top: 1px solid #bbb">
			<article class="half_view">
				<div class="layer_title under-line">
					<h3>{{ credit_info.payment_mthd_name}}
						({{ (credit_info.payment_mthd_number).slice(-4) }})</h3>
				</div>
				<div class="mt-10 pa-10-30">
					<div
						class=""
						@click="onSetup"
					>
						{{  $language.mypage.credit.credit_setup_title }}
					</div>
					<div
						class="mt-10"
						@click="onDelete"
					>
						{{  $language.mypage.credit.credit_delete_title }}
					</div>
				</div>

			</article>
			<button class="btn_close" @click="$emit('cancel')">
				<i class="icon icon-close"></i>
			</button>
		</div>

		<Popup_confirm
			v-if="is_setup"
			@click="postCreditSetup"
			@cancel="is_setup = false"
		>
			<template v-slot:title>{{  $language.mypage.credit.credit_setup_title }}</template>
			<template
				v-slot:main-txt
			>{{  $language.mypage.credit.credit_setup_txt }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.mypage.credit.credit_setup_txt_sub }}</template>
			<template
				v-slot:name-cancel
			>{{  $language.common.cancel }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.common.ok }}</template>
		</Popup_confirm>

		<Popup_confirm
			v-if="is_delete"
			@click="postCreditDelete"
			@cancel="is_delete = false"
		>
			<template v-slot:title>{{  $language.mypage.credit.credit_delete_title }}</template>
			<template
				v-slot:main-txt
			>{{  $language.mypage.credit.credit_delete_txt }}</template>
			<template
				v-slot:sub-txt
			>{{  $language.mypage.credit.credit_delete_txt_sub }}</template>
			<template
				v-slot:name-cancel
			>{{  $language.common.cancel }}</template>
			<template
				v-slot:name-confirm
			>{{  $language.common.ok }}</template>
		</Popup_confirm>
	</div>
</template>

<script>
import Popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'mafiaCreditMore'
	,
	components: { Popup_confirm},
	props: ['user', 'credit_info']
	, data: function(){
		return {
			program: {
				name: this.$language.nft.nft_card

			}
			, items: []
			, item_main_nft: {}
			, is_setup: false
			, is_delete: false
			, result_type: false
			, is_result: true
		}
	}
	, methods: {
		onSetup: function(){
			this.is_setup = true
		}
		, onDelete: function(){
			this.is_delete = true
		}

		, postCreditSetup: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_credit_setup
					, data: {
						member_number: this.user.member_number
						, payment_mthd_number: this.credit_info.payment_mthd_number
					}
					, type: true
				})
				if(result.success){
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postCreditDelete: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_credit_delete
					, data: {
						member_number: this.user.member_number
						, payment_mthd_number: this.credit_info.payment_mthd_number
					}
					, type: true
				})
				if(result.success){
					this.$emit('click')
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
	}
}
</script>

<style>
	.under-line { border-bottom: 1px solid #ddd}
</style>