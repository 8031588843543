<template>
	<div class="mt-50 size-px-16 full-height">
		<ul
			v-if="items_credit.length > 0"
			class="pt-20"
		>
			<li
				v-for="(credit, c_index) in items_credit"
				:key="'credit_' + c_index"
				class="mt-10 pa-10 flex-row justify-space-between"
			>
				<div class="flex-1">
					{{ credit.payment_mthd_name }}
					({{ (credit.payment_mthd_number).slice(-4) }})
					<label
						v-if="credit.representation_payment_fg == 'Y'"
						class="label label-primary"
					>{{ $language.mypage.credit.credit_setup_name }}</label>
				</div>
				<button
					@click="onMore(credit)"
				><v-icon>mdi-dots-vertical</v-icon></button>
			</li>
		</ul>

		<div
			v-else
			class="list_none flex-column justify-center items-center"
			style="overflow: hidden; height: 100%;"
			:style="$route.params.code ? 'padding-top: 100%' : ''"
		>
			<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
			<span>{{  $language.mypage.credit.credit_none_txt }}</span>
		</div>

		<MafiaCreditMore
			v-if="item_more.payment_mthd_number"
			:user="user"
			:credit_info="item_more"

			@click="setupCredit"
			@cancel="offMore"
		></MafiaCreditMore>

	</div>
</template>

<script>

	import MafiaCreditMore from "@/view/Mypage/mafia-credit-more";
	export default {
		name: 'mafiaCredit'
		, components: {MafiaCreditMore}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'mafiaCredit'
					, title: this.$language.mypage.credit_management
					, not_footer: true
					, type: 'mypage'
					, from: 'mafia100'
					, is_save: true
					, callBack: {
						name: 'to_credit_regist'
					}
				}
				, item_search: {
					page: 1
					, list_cnt: 10
				}
				, items_credit: []
				, item_more: {}
				, is_more: false
			}
		}
		, computed: {

		}
		, methods: {
			getCreditList: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_credit_list
						, data: {
							member_number: this.user.member_number
							, page_number: this.item_search.page
							, pagerecnum: this.item_search.list_cnt
						}
						, type: true
					})
					if(result.success){
						this.items_credit = result.data.payment_mthd_list
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onMore: function(item){
				this.item_more = item
				this.is_more = true
			}
			, offMore: function(){
				this.item_more = {}
				this.is_more = false
			}
			, setupCredit: function(){
				this.getCreditList()
				this.offMore()
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getCreditList()
			this.$bus.$on('callBack', (call) => {
				if(call.name == 'to_credit_regist'){
					this.$emit('to', { name: 'mafiaCreditRegist'})
					this.$bus.$off('callBack')
				}
			})
		}
	}
</script>

<style>
</style>